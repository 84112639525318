export const DOCUMENT_TEMPLATE_TYPE = {
    PLAYGROUND_REPORT_COVER: {
        code: "PLAYGROUND_REPORT_COVER",
        name: "Jegyzőkönyv címoldal",
    },
    PLAYGROUND_REPORT_EQUIPMENT: {
        code: "PLAYGROUND_REPORT_EQUIPMENT",
        name: "Eszköz adatlap",
    },
    PLAYGROUND_REGISTER_SHEET: {
        code: "PLAYGROUND_REGISTER_SHEET",
        name: "Nyilvántartó lap",
    },
    PLAYGROUND_EQUIPMENT_CERTIFICATE_OF_USE: {
        code: "PLAYGROUND_EQUIPMENT_CERTIFICATE_OF_USE",
        name: "Használatba vételi igazolás játszótéri új eszközökhöz",
    },
    SPORT_EQUIPMENT_CERTIFICATE_OF_USE: {
        code: "SPORT_EQUIPMENT_CERTIFICATE_OF_USE",
        name: "Használatba vételi igazolás sporteszközökhöz",
    },
    SPORT_EQUIPMENT_REPORT_COVER: {
        code: "SPORT_EQUIPMENT_REPORT_COVER",
        name: "Sporteszköz - Jegyzőkönyv címoldal",
    },
    SPORT_EQUIPMENT_REPORT_EQUIPMENT: {
        code: "SPORT_EQUIPMENT_REPORT_EQUIPMENT",
        name: "Sporteszköz - Eszköz adatlap",
    },
    SPORT_EQUIPMENT_CERTIFICATE_OF_CONFORMITY: {
        code: "SPORT_EQUIPMENT_CERTIFICATE_OF_CONFORMITY",
        name: "Sporteszköz tanúsítvány",
    },
};

export const DOCUMENT_TEMPLATE_TYPES = [
    DOCUMENT_TEMPLATE_TYPE.PLAYGROUND_REPORT_COVER,
    DOCUMENT_TEMPLATE_TYPE.PLAYGROUND_REGISTER_SHEET,
    DOCUMENT_TEMPLATE_TYPE.PLAYGROUND_REPORT_EQUIPMENT,
    DOCUMENT_TEMPLATE_TYPE.PLAYGROUND_EQUIPMENT_CERTIFICATE_OF_USE,
    DOCUMENT_TEMPLATE_TYPE.SPORT_EQUIPMENT_CERTIFICATE_OF_USE,
    DOCUMENT_TEMPLATE_TYPE.SPORT_EQUIPMENT_REPORT_COVER,
    DOCUMENT_TEMPLATE_TYPE.SPORT_EQUIPMENT_REPORT_EQUIPMENT,
    DOCUMENT_TEMPLATE_TYPE.SPORT_EQUIPMENT_CERTIFICATE_OF_CONFORMITY,
];
